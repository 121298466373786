import React from 'react';
import {
    Row,
    Navbar,
    Nav,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    NavItem,
} from 'reactstrap';
import { NavLink } from 'react-router-dom';
// hoc
import { withNamespaces } from 'react-i18next';
// constants
import { LANGUAGE, DEFAULT_ROUTE, HEADER_LINKS } from '../../Constants/AppConstants';
// css
import './Header.scss'
// assets
import Logo from '../../Assets/Logo.png';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';

class Header extends React.Component {

    isAttendanceActive = () => {
        const { location } = this.props; // Access the location prop
        return location && location.pathname.startsWith('/attendance'); // Ensure location is not undefined
    };

    renderLinks = () => {

        const linksToRender = this.props.roleId === 5
            ? HEADER_LINKS.filter((link) => link.TEXT === 'header.attendance') :
            this.props.roleId === 6 ? HEADER_LINKS.filter((link) => link.TEXT === 'header.banners')
                : HEADER_LINKS;

        return linksToRender.map((element, index) => {
            if (element.SUBMENU) {


                return (
                    <UncontrolledDropdown nav inNavbar key={index}>
                        <DropdownToggle
                            nav
                            className={`custom-dropdown-toggle ${this.isAttendanceActive() ? 'active' : ''}`}  // Conditionally add active class
                        >
                            {this.props.i18n.t(element.TEXT)}
                        </DropdownToggle>
                        <DropdownMenu>
                            {element.SUBMENU.map((submenuItem, subIndex) => (
                                <DropdownItem key={subIndex}>
                                    <NavLink
                                        to={submenuItem.PATH}
                                        className="dropdown-item submenu-item"
                                        activeClassName="active-submenu"
                                    >
                                        {this.props.i18n.t(submenuItem.TEXT)}
                                    </NavLink>
                                </DropdownItem>
                            ))}
                        </DropdownMenu>
                    </UncontrolledDropdown>
                );
            } else {
                return (
                    <NavItem key={index}>
                        <NavLink
                            to={element.ROUTE_OBJECT.PATH}
                            activeClassName="active"
                            isActive={(match, location) =>
                                match || location.pathname.includes(element.ROUTE_OBJECT.PATH)
                            }  // Check if current path includes the route to apply active class
                        >
                            {this.props.i18n.t(element.TEXT)}
                        </NavLink>
                    </NavItem>
                );
            }
        });
    };


    renderDropdown = (object) => {
        return (
            <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                    {this.props.i18n.t(object.title)}
                </DropdownToggle>
                <DropdownMenu right={(this.props.i18n.language !== LANGUAGE.AR)}>
                    {
                        object.dropdownItems.map((element, index) => {
                            return (
                                <DropdownItem key={index} onClick={element.onClick}>
                                    {this.props.i18n.t(element.title)}
                                </DropdownItem>
                            );
                        })
                    }
                </DropdownMenu>
            </UncontrolledDropdown>
        );
    }

    dropdownData = {
        title: 'header.language',
        dropdownItems: [
            {
                title: 'header.english',
                onClick: () => this.props.i18n.changeLanguage(LANGUAGE.EN),
            },
            {
                title: 'header.arabic',
                onClick: () => this.props.i18n.changeLanguage(LANGUAGE.AR),
            }
        ],
    };

    render() {
        return (
            <div className="header">
                <div className="container h-100">
                    <Row className="h-100">
                        <Navbar expand="md">
                            <NavLink to={DEFAULT_ROUTE}>
                                <img
                                    src={Logo}
                                    alt='logo'
                                />
                            </NavLink>
                            <Nav className='navbar-links px-5'>
                                {this.renderLinks()}
                            </Nav>
                            <Nav className='margin-inline-start-auto'>
                                {/* this.renderDropdown(this.dropdownData) */}
                                <UncontrolledDropdown nav inNavbar>
                                    <DropdownToggle className="circular-btn">
                                        {this.props.initials}
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                        <div className="circular-div">
                                            {this.props.initials}
                                        </div>
                                        <div className="user-name text-center">{this.props.userDisplayName}</div>
                                        <DropdownItem divider />
                                        <DropdownItem
                                            className="text-center"
                                            onClick={this.props.logout}
                                        >
                                            {this.props.i18n.t('header.logout')}
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </Nav>
                        </Navbar>
                    </Row>
                </div>
            </div>
        );
    }
}

export default withRouter(withNamespaces()(Header));